<template>
  <!-- 面包屑 -->
  <a-breadcrumb>
    <a-breadcrumb-item>分销管理（旧）</a-breadcrumb-item>
    <a-breadcrumb-item>分销商管理</a-breadcrumb-item>
  </a-breadcrumb>
  <!-- 功能条 -->
  <div class="tool-view">
    <!-- 弹簧 -->
    <div style="flex: 1;"></div>
    <!-- 操作 -->
    <a-button type="primary" @click="touchAdd"><plus-outlined />添加分销商</a-button>
  </div>
  <!-- 列表 -->
  <a-table
    class="table-view"
    :data-source="dataSource"
    :columns="columns"
    :row-key="record => record.id"
    :pagination="pagination"
    :loading="isLoading"
    @change="handleTableChange"
  >
    <!-- 自定义行内容 -->
    <template #bodyCell="{ column, record }">
      <!-- 分成比例 -->
      <template v-if="column.key === 'cash_rate'">
        {{ record[column.key] + '%' }}
      </template>
      <!-- 小程序 -->
      <template v-if="column.key === 'type'">
        {{ (ResellerAttrs.find(item => item.value === record.type) || {}).text }}
      </template>
      <!-- 操作 -->
      <template v-if="column.key === 'operation'">
        <a class="operation-item" @click="touchEdit(record)">编辑</a>
      </template>
    </template>
  </a-table>
  <Add ref="RefAdd" @success="getList"></Add>
</template>

<script setup>
import { onBeforeMount, ref, reactive } from 'vue'
import { message } from 'ant-design-vue'
import { distributorList } from '@/api/copyright'
import Add from './components-reseller-manager/Add'
import { ResellerAttrs } from '@/utils/constantList'

// 每日数据
let RefAdd = ref(null)
// 加载
let isLoading = ref(false)
// 数据源
let dataSource = ref([])
// 分页信息
let pagination = reactive({
  total: 0,
  current: 1,
  pageSize: 10,
  showSizeChanger: true
})
// 列数据源
let columns = ref([
  {
    title: '分销商ID',
    dataIndex: 'id',
    key: 'id'
  },
  {
    title: '分销商名称',
    dataIndex: 'name',
    key: 'name'
  },
  {
    title: '小程序',
    dataIndex: 'type',
    key: 'type'
  },
  {
    title: '绑定',
    dataIndex: 'bind',
    key: 'bind'
  },
  {
    title: '分成比例',
    dataIndex: 'cash_rate',
    key: 'cash_rate'
  },
  {
    title: '入库日期',
    dataIndex: 'created_at',
    key: 'created_at'
  },
  {
    title: '操作',
    dataIndex: 'operation',
    key: 'operation'
  }
])

// 钩子函数
onBeforeMount (() => {
  // 获取推广数据列表
  getList()
})

// 新增
function touchAdd () {
  RefAdd.value.showDrawer()
}

// 编辑
function touchEdit (record) {
  RefAdd.value.showDrawer(record)
}

// 分页处理
function handleTableChange (p, filters, sorter) {
  pagination.current = p.current
  pagination.pageSize = p.pageSize
  getList()
}

// 获取推广数据列表
function getList () {
  isLoading.value = true
  const params = {
    page: pagination.current,
    page_size: pagination.pageSize
  }
  distributorList(params).then(res => {
    isLoading.value = false
    const { code, data, msg } = res
    if (code === 0) {
      const list = data.data
      list.forEach(item => {
        let strs = []
        if (item.type === 1) {
          const agents = item.project_agents || []
          agents.forEach(item => {
            strs.push(item.name)
          })
        } else {
          const projects = item.projects || []
          projects.forEach(item => {
            strs.push(item.name)
          })
        }
        item.bind = strs.join('、')
      })
      dataSource.value = list
      pagination.total = data.total
    } else {
      message.error(res.message || msg)
    }
  })
}
</script>

<style scoped>
.tool-view {
  width: 100%;
  display: flex;
  align-items: center;
  margin-top: 30px;
}
.table-view {
  margin-top: 30px;
}
.tool-title:not(:first-child) {
  margin-left: 16px;
}

</style>