<template>
  <a-drawer
    title="创建/编辑分销商"
    width="700"
    :visible="visible"
    :closable="false"
    :footer-style="{ textAlign: 'right' }"
    @close="onClose"
  >
    <!-- from -->
    <a-form
      ref="RefForm"
      :model="formState"
      :label-col="{ span: 4 }"
      :wrapper-col="{ span: 16 }"
    >
      <!-- 名称 -->
      <a-form-item
        label="名称"
        name="name"
        :rules="[{ required: true }]"
      >
        <a-input
          v-model:value="formState.name"
          placeholder="请输入"
        />
      </a-form-item>
      <!-- 公司主体 -->
      <a-form-item
        name="company_name"
        :rules="[{ required: true }]"
      >
        <template #label>
          <span style="margin-right: 2px;">公司主体</span>
          <a-tooltip>
            <template #title>需跟合同签约的公司主体名称一致</template>
            <info-circle-outlined />
          </a-tooltip>
        </template>
        <a-input
          v-model:value="formState.company_name"
          placeholder="请输入"
        />
      </a-form-item>
      <!-- 收款账号 -->
      <a-form-item
        label="收款账号"
        name="bank_account"
        :rules="[{ required: true }]"
      >
        <a-input
          v-model:value="formState.bank_account"
          placeholder="请输入"
        />
      </a-form-item>
      <!-- 开户行 -->
      <a-form-item
        label="开户行"
        name="bank_branch"
        :rules="[{ required: true }]"
      >
        <a-input
          v-model:value="formState.bank_branch"
          placeholder="请输入"
        />
      </a-form-item>
      <!-- 分成比例 -->
      <a-form-item
        name="cash_rate"
        :rules="[{ required: true, validator: validatorCashRate }]"
      >
        <template #label>
          <span style="margin-right: 2px;">分成比例</span>
          <a-tooltip>
            <template #title>按充值金额进行比例分成。修改分成比例只对修改后的数据生效。</template>
            <info-circle-outlined />
          </a-tooltip>
        </template>
        <a-input
          class="form-input"
          v-model:value="formState.cash_rate"
          placeholder="请输入"
          suffix="%"
        />
      </a-form-item>
      <!-- 结算周期 -->
      <a-form-item
        label="结算周期"
        name="cash_cycle"
        :rules="[{ required: true }]"
      >
        <a-select
          class="form-input"
          v-model:value="formState.cash_cycle"
          placeholder="请选择"
          :getPopupContainer="(triggerNode) => triggerNode.parentNode"
        >
          <a-select-option
            v-for="item in SettlementCycle"
            :key="item.value"
            :value="item.value"
          >
            {{ item.text }}
          </a-select-option>
        </a-select>
      </a-form-item>
      <!-- 小程序 -->
      <a-form-item
        label="小程序"
        name="type"
      >
      <a-radio-group v-model:value="formState.type">
        <a-radio
            v-for="item in ResellerAttrs"
            :key="item.value"
            :value="item.value"
          >
            {{ item.text }}
          </a-radio>
      </a-radio-group>
      </a-form-item>
      <!-- 绑定二级分销 -->
      <a-form-item
        v-if="formState.type === 1"
        label="绑定二级分销"
        name="project_agent_ids"
      >
        <a-select
          v-model:value="formState.project_agent_ids"
          mode="multiple"
          placeholder="请选择"
          :getPopupContainer="(triggerNode) => triggerNode.parentNode"
          :filterOption="filterOption"
        >
          <a-select-option
            v-for="item in agents"
            :key="`${item.id}-${item.name}`"
            :value="item.id"
          >
            {{ item.name }}
          </a-select-option>
        </a-select>
      </a-form-item>
      <!-- 绑定项目 -->
      <a-form-item
        v-if="formState.type === 2"
        label="绑定项目"
        name="project_ids"
      >
        <a-select
          v-model:value="formState.project_ids"
          mode="multiple"
          placeholder="请选择"
          :getPopupContainer="(triggerNode) => triggerNode.parentNode"
          :filterOption="filterOption"
        >
          <a-select-option
            v-for="item in projects"
            :key="`${item.id}-${item.name}`"
            :value="item.id"
          >
            {{ item.name }}
          </a-select-option>
        </a-select>
      </a-form-item>
      <!-- 推广渠道 -->
      <a-form-item
        v-if="formState.type === 2"
        label="推广渠道"
        name="channels"
        :rules="[{ required: true }]"
      >
        <a-select
          v-model:value="formState.channels"
          mode="multiple"
          placeholder="请选择"
          :filter-option="filterOption"
          :getPopupContainer="(triggerNode) => triggerNode.parentNode"
        >
          <a-select-option
            v-for="item in ChannelTypes"
            :key="`${item.value}-${item.text}`"
            :value="item.value"
          >
            {{ item.text }}
          </a-select-option>
        </a-select>
      </a-form-item>
      <!-- 推广客户端 -->
      <a-form-item
        v-if="formState.type === 2"
        label="推广客户端"
        name="clients"
        :rules="[{ required: true }]"
      >
        <a-select
          v-model:value="formState.clients"
          mode="multiple"
          placeholder="请选择"
          :filter-option="filterOption"
          :getPopupContainer="(triggerNode) => triggerNode.parentNode"
        >
          <a-select-option
            v-for="item in ClientTypes"
            :key="`${item.value}-${item.text}`"
            :value="item.value"
          >
            {{ item.text }}
          </a-select-option>
        </a-select>
      </a-form-item>
    </a-form>
    <!-- 尾部 -->
    <template #footer>
      <a-button style="margin-right: 8px" @click="onClose">取消</a-button>
      <a-button type="primary" @click="touchSubmit">确定</a-button>
    </template>
    <!-- 加载 -->
    <Loading :loading="isLoading"></Loading>
  </a-drawer>
</template>

<script setup>
import { reactive, ref } from 'vue'
import { nextTick } from 'process'
import { ChannelTypes, ClientTypes, SettlementCycle, ResellerAttrs } from '@/utils/constantList'
import Loading from '@/components/Loading'
import Pub from '@/utils/public'
import { distributorSave, distributorProjectList, distributorDetail, distributorAgentList } from '@/api/copyright'
import { message } from 'ant-design-vue'

// emit
const emit = defineEmits(['success'])
// from
const RefForm = ref(null)
// 显示
let visible = ref(false)
// 加载
let isLoading = ref(false)
// 修改对象
let detail = ref(null)
// 项目列表
let projects = ref([])
// 二级分销列表
let agents = ref([])
// 表单参数
const formState = ref({
  // 名称
  name: undefined,
  // 公司主体
  company_name: undefined,
  // 收款账号
  bank_account: undefined,
  // 开户行
  bank_branch: undefined,
  // 分成比例
  cash_rate: undefined,
  // 周期
  cash_cycle: undefined,
  // 分销商属性
  type: 1,
  // 项目
  project_ids: [],
  // 二级分销列表
  project_agent_ids: [],
  // 推广渠道
  channels: [],
  // 推广客户端
  clients: []
})

// 显示
function showDrawer (data) {
  // 记录
  detail.value = data
  // 显示
  visible.value = true
  // 延迟处理
  nextTick(() => {
    // 获取项目列表
    getProjectList()
    // 获取二级分销列表
    getAgentList()
    // 获取项目
    if (!!data) { getDetail() }
  })
}

// 模糊匹配
function filterOption (input, option) {
  return `${option.key}`.toLowerCase().includes(`${input || ''}`.toLowerCase())
}

// 关闭
function onClose () {
  if (!isLoading.value) {
    resetFields()
    visible.value = false
  }
}

// 重置
function resetFields () {
  RefForm.value.resetFields()
}

// 获取项目列表
function getProjectList () {
  distributorProjectList().then(res => {
    isLoading.value = false
    const { code, msg, data } = res
    if (code === 0) {
      projects.value = data
    } else {
      message.error(res.message || msg)
    }
  })
}

// 获取二级分销列表
function getAgentList () {
  distributorAgentList().then(res => {
    isLoading.value = false
    const { code, msg, data } = res
    if (code === 0) {
      agents.value = data
    } else {
      message.error(res.message || msg)
    }
  })
}

// 片库详情
function getDetail () {
  isLoading.value = true
  distributorDetail({ id: detail.value.id }).then(res => {
    isLoading.value = false
    const { code, data } = res
    if (code === 0) {
      // 处理项目ID
      const project_ids = []
      const projects = data.projects || []
      projects.forEach(item => {
        project_ids.push(item.id)
      })
      data.project_ids = project_ids
      // 处理二级分类ID
      const project_agent_ids = []
      const agents = data.project_agents || []
      agents.forEach(item => {
        project_agent_ids.push(item.id)
      })
      data.project_agent_ids = project_agent_ids
      // 赋值
      formState.value = data
    } else {
      message.error(res.message)
    }
  }).catch(() => {
    isLoading.value = false
    message.error('获取详情失败')
  })
}

// 提交
function touchSubmit () {
  RefForm.value.validate().then((res => {
    // 上传数据
    const params = {
      id: detail.value && detail.value.id,
      ...res
    }
    isLoading.value = true
    distributorSave(params).then((res) => {
      isLoading.value = false
      if (res.code === 0) {
        if (params.id) {
          message.success('编辑成功')
        } else {
          message.success('新增成功')
        }
        onClose()
        emit('success')
      } else {
        message.error(res.message)
      }
    }).catch(() => {
      isLoading.value = false
      message.error('提交失败')
    })
  })).catch((err) => {
    console.log(err)
  })
}

// 效验 - 分成比例
function validatorCashRate (rule, value) {
  if (!value) {
    return Promise.reject('请输入${label}')
  } else if (!Pub.REG_IS_NUMBER(value)) {
    return Promise.reject('必须为正数')
  } else if (Pub.CHECK_NUMBER_DECIMAL(value, 2)) {
    return Promise.reject('最多 2 位小数')
  }
  return Promise.resolve()
}

// 抛出
defineExpose({
  showDrawer
})

</script>

<style scoped>
.form-input {
  width: 50%;
}
.form-item-clear {
  margin-bottom: 0;
}
.form-upload-hint {
  padding-top: 6px;
  color: #8D8D8D;
}
.form-upload-list {
  display: flex;
  flex-wrap: wrap;
  margin-left: -10px;
}
.image-view.form-upload-item {
  margin-top: 10px;
  margin-left: 10px;
  width: 118px;
  height: 148px;
}
</style>